import React, {FC, memo, useEffect, useState} from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import chevronDown from "assets/chevron-down-dark.svg";
import { COUNTRY, COUNTRY_KEY } from "constants/store";
import { updateUserCountryPreferenceAction } from "actions/usersAction";
import { useDispatch, useSelector } from "react-redux";
import { removeItemFromCookieAction, setItemInCookieAction } from "actions/cookieStorageAction";
import { ReduxState } from "types";
import { COOKIES, getCookie } from "utils/cookies";
import { getPathNameWithStoreType, isFromNativeApp } from "utils/misc";
import { isInvestorStore } from "utils/auth";

const DropdownMenu = dynamic(() => import('./DropdownMenu'));

type OptionType = { label: string; value: string | number; icon: string; code: string };

interface SelectCountryProps {
  countryData: {
    countryName: string;
    countryId: number;
  }[]
}

const SelectCountry: FC<SelectCountryProps> = ({ countryData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxState) => state?.user);
  const selectedCountry = user?.selectedCountry;

  const [showDropdownMenu, setDropdownMenu] = useState(false);

  const isUSCountryAvailable = countryData?.find(country => (country.countryName === COUNTRY.us.code || country.countryName === COUNTRY.international.code));

  const updateDefaultCountryPreference = () => {
    if (!user?.storesFetched) return;

    if (!countryData?.length) {
      removeItemFromCookieAction(COOKIES.countryPreference);
      return;
    }

    const selectedCountryFromCookie = getCookie(null, COOKIES.countryPreference);

    const selectedCountryValue = selectedCountryFromCookie ? JSON.parse(selectedCountryFromCookie) : 'null';
    const isSelectedCountryValuePresent = countryData?.find(country => country.countryId === selectedCountryValue?.id);

    // If selected country is not present in the cookie or if selected country is not present in the country list, then set default country as India
    if (!selectedCountryValue || !isSelectedCountryValuePresent) {
      const selectedCountryDetails = {
        id: countryData?.find(country => country.countryName === COUNTRY.india.code)?.countryId,
        label: COUNTRY.india.label,
        code: COUNTRY.india.code,
      }

      dispatch(updateUserCountryPreferenceAction(selectedCountryDetails));
      dispatch(setItemInCookieAction(COOKIES.countryPreference, JSON.stringify(selectedCountryDetails)));
    } 
    // If selected country is present in the cookie, then set selected country as default country
    else {
      dispatch(updateUserCountryPreferenceAction(selectedCountryValue));
      dispatch(setItemInCookieAction(COOKIES.countryPreference, selectedCountryFromCookie));
    }
  }

  useEffect(() => {
    updateDefaultCountryPreference();
  }, [countryData, user?.storesFetched]);

  const handleOptionChange = (option: OptionType) => {
    // If selected country is same as the option selected, then return.
    if (option.value === selectedCountry?.id) return;

    const selectedCountryDetails = {
      id: option.value,
      label: option.label,
      code: option.code,
    }

    dispatch(setItemInCookieAction(COOKIES.countryPreference, JSON.stringify(selectedCountryDetails)));
    window.location.replace(getPathNameWithStoreType("/"))
  };

  if (isInvestorStore() || isFromNativeApp() || !countryData?.length || !isUSCountryAvailable || !selectedCountry?.id) return <div className="w-[178px] smScreen:hidden"></div>;

  return (
    <div className="flex items-center">
      <div
        className="relative"
        onMouseOver={() => setDropdownMenu(true)}
        onMouseLeave={() => setDropdownMenu(false)}
      >
        <button
          className="flex items-center gap-[5px] cursor-pointer h-[60px] smScreen:h-[48px]" 
          id="select-country-button"
        >
          <span className="text-[#424242] text-sm font font-semibold smScreen:hidden">Dropshipping In</span>
          <Image
            src={COUNTRY_KEY[selectedCountry.code]?.icon || "/"}
            alt="Country Icon"
            loading="eager"
            height={20}
            width={20}
          />
          <Image
            src={chevronDown}
            alt="Select Country"
            loading="eager"
          />
        </button>
        <DropdownMenu
          showOption={showDropdownMenu}
          onOptionChange={handleOptionChange}
          options={countryData?.map(country => ({
            label: COUNTRY_KEY[country.countryName]?.label || "--",
            value: country.countryId,
            icon: COUNTRY_KEY[country.countryName]?.icon || "/",
            code: country.countryName,
          }))}
          imageHeight={18}
          imageWidth={18}
        />
      </div>
      <div className='border-l h-[32px] ml-[20px] smScreen:hidden'></div>
    </div>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.countryData === nextProps.countryData;
};

export default memo(SelectCountry, areEqual);
